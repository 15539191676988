import React, { useRef, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { JSLogo } from 'icons/JSLogo'
import { JungleScoutLogo } from 'icons/JungleScoutLogo/JungleScoutLogoV2'

import { TRANSITION_TIMING } from 'constants/sidebar'
import { NAVBAR_STYLE } from 'constants/styles'

import { logException } from '../config/sentry'
import { media } from 'helpers/media_queries'
import { buildMenuItems } from 'helpers/menu_item_builder'

import { flagDataTypes } from 'types/general'
import { userType } from 'types/user'
import { membershipInfoType } from 'types/memberships'

import { VerticalScrollingBlock } from 'ui_elements/VerticalScrollingBlock/VerticalScrollingBlock'
import SidebarToggle from './sidebar/SidebarToggle'
import SidebarItem from './sidebar/SidebarItem'

const checkForMediaQuery = (props, style) => {
  const { ignoreToggleState, collapsed } = props

  if (ignoreToggleState) return media.desktop(style)
  if (!collapsed) return style

  return null
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  min-height: 48px;
  background-color: ${props =>
    props.collapsed ? 'transparent' : props.theme.colors.grey950};
  transition: background-color 0.3s ${TRANSITION_TIMING};

  &:hover {
    cursor: ${props => (props.disabled ? '' : 'pointer')};
  }
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  flex: 1;
  background-color: ${props => props.theme.colors.grey900};
  width: ${NAVBAR_STYLE.collapsedWidth};
  max-width: ${NAVBAR_STYLE.collapsedWidth};
  min-width: ${NAVBAR_STYLE.collapsedWidth};
  align-items: center;
  ${props => {
    return checkForMediaQuery(
      props,
      `width: ${NAVBAR_STYLE.width};
      max-width: ${NAVBAR_STYLE.width};
      min-width: ${NAVBAR_STYLE.width};
      align-items: flex-start;`
    )
  }};
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${props => props.theme.colors.grey300};
  transition: all 0.3s ${TRANSITION_TIMING}, height 0s;
  z-index: 999998;
`

const ItemsWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  .spacer {
    flex-grow: 1;
  }
`
const StyledVerticalScrollingBlock = styled(VerticalScrollingBlock)`
  width: 100%;
  height: 100%;
  padding-top: 8px;
`

const Sidebar = ({
  toggleSidebar,
  ignoreToggleState,
  expandedOption,
  admin,
  flagData,
  appType,
  membershipInfo,
  expandSidebarOption,
  currentPath,
  user,
  user: { permissions, created_at },
  t,
  locked,
  enableWrapping,
  isImpersonating,
  featureLimits
}) => {
  const [collapsed, setCollapsed] = useState(!locked)

  useEffect(() => {
    const handleResize = () => {
      window.scrollTo(0, 0)
    }
    window.addEventListener('resize', handleResize)

    return window.removeEventListener('resize', handleResize)
  }, [])

  const sidebarRef = useRef(null)

  const onToggle = () => {
    toggleSidebar()
    // to save passing the collapsed prop all the way through various levels of the app
    // when it changes, fire off a dom event other elements can listen to
    try {
      const event = new CustomEvent('sidebarresized', {
        detail: { collapsed }
      })
      sidebarRef.current.dispatchEvent(event)
    } catch (ex) {
      logException(ex)
    }
  }

  const renderItem = (item, key) => {
    return (
      <SidebarItem
        key={`menu${key}`}
        item={item}
        collapsed={collapsed}
        ignoreToggleState={ignoreToggleState}
        expandedOption={expandedOption}
        admin={admin}
        flagData={flagData}
        appType={appType}
        membershipInfo={membershipInfo}
        currentPath={currentPath}
        onToggle={onToggle}
        expandSidebarOption={expandSidebarOption}
        enableWrapping={enableWrapping}
        permissions={permissions}
        isImpersonating={isImpersonating}
        user={user}
      />
    )
  }

  const getLogo = () => {
    const jsLogoProps = collapsed
      ? {
          opacity: 1,
          width: '29px',
          height: '20px'
        }
      : {
          opacity: 0,
          width: '0px',
          height: '0px'
        }
    const jungleScoutLogoProps = collapsed
      ? {
          opacity: 0,
          height: '0px',
          width: '0px'
        }
      : {
          opacity: 1,
          height: '20px',
          width: '131px'
        }

    return (
      <>
        <JSLogo isGradient {...jsLogoProps} />
        <JungleScoutLogo {...jungleScoutLogoProps} />
      </>
    )
  }

  const handleMouseOver = () => {
    if (!locked && collapsed) {
      setCollapsed(false)
    }
  }

  const handleMouseLeave = () => {
    if (!locked && !collapsed) {
      setCollapsed(true)
    }
  }

  const buildMenuItemsProps = {
    t,
    created_at,
    flagData,
    hasActiveMembership: membershipInfo.hasActiveMembership,
    featureLimits
  }
  const menuItems = buildMenuItems(buildMenuItemsProps)
  const primaryItems = menuItems.filter(item => !item.secondary)
  const secondaryItems = menuItems.filter(item => item.secondary)

  const navigateHome = () => {
    window.location.hash = '/dashboard'
  }

  return (
    <Wrapper
      ignoreToggleState={ignoreToggleState}
      collapsed={collapsed}
      data-collapsed={collapsed}
      ref={sidebarRef}
      id="sidebar"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}>
      <LogoWrapper collapsed={collapsed} onClick={navigateHome}>
        {getLogo()}
      </LogoWrapper>
      <StyledVerticalScrollingBlock maxHeight="100%">
        <ItemsWrapper>
          {primaryItems.map(renderItem)}
          <div className="spacer" />
          {secondaryItems.map(renderItem)}
        </ItemsWrapper>
      </StyledVerticalScrollingBlock>
      <SidebarToggle collapsed={collapsed} locked={locked} onClick={onToggle} />
    </Wrapper>
  )
}

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  expandSidebarOption: PropTypes.func.isRequired,
  expandedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  enableWrapping: PropTypes.bool,
  locked: PropTypes.bool.isRequired,
  ignoreToggleState: PropTypes.bool.isRequired,
  admin: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  flagData: flagDataTypes.isRequired,
  user: userType.isRequired,
  membershipInfo: membershipInfoType.isRequired,
  appType: PropTypes.string.isRequired,
  featureLimits: PropTypes.shape({
    aiFeatures: PropTypes.shape({
      featureLimit: PropTypes.number
    })
  }).isRequired,
  isImpersonating: PropTypes.bool
}

Sidebar.defaultProps = {
  expandedOption: null,
  enableWrapping: false,
  isImpersonating: false
}

export default withTranslation(['generic', 'sidebar'])(Sidebar)
